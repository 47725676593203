<template>
  <el-dialog
    append-to-body
    width="950px"
    top="5vh"
    :title="dialogTitle"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    center
  >
    <el-form
      class="staff-from"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-position="right"
      :model="form"
      :size="subUnitSize"
      @submit.native.prevent
    >
      <el-form-item label="" prop="name">
        <el-input
          v-model.trim="form.goods_name"
          placeholder="请输入商品名称"
          clearable
          @clear="getList"
          style="width: 400px; margin-left: 150px; margin-right:20px;"
          @keydown.enter.native="getList"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getList"
          ></el-button>
        </el-input>
        <!-- 一品多商时加客户查询条件 -->
        <template v-if="sourceType == 3">
          <span>客户名称：</span>
          <el-select v-model="form.merchant_id" filterable clearable placeholder="请选择" @focus="getMerchantSel" @change="getList">
            <el-option v-for="item in merchantArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </template>
      </el-form-item>
    </el-form>

    <div class="boxWrap" v-if="visible">
      <div class="left">
        <div class="title"><span v-if="!!childText">【{{childText}}】</span>已绑定商品: {{ bindingNum }}</div>
        <div class="flex">
          <div class="cate">
            <div
              :class="idx1 == index ? 'cateTitle active' : 'cateTitle'"
              v-show="item.list.length > 0"
              v-for="(item, index) in bindingArr"
              :key="index"
              @click="handleCate1(index)"
            >
              {{ item.cate_name }} 【{{ item.list.length }}】
            </div>
          </div>
          <div class="goods" v-if="bindingArr.length > 0">
            <p v-for="item in bindingArr[idx1].list" :key="item.id">
              <el-tag closable type="info" size="mini" @close="del(item)">
                {{ item.good_name }}
              </el-tag>
            </p>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="right">
        <div class="title">选择商品</div>
        <div class="flex selGoodsTop">
          <span style="margin-right: 100px; padding-bottom: 5px">商品分类</span>
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
        </div>
        <div class="content" v-if="cateArr[0].list.length > 0">
          <div class="cate">
            <div
              :class="idx == cateIndex ? 'cateTitle active' : 'cateTitle'"
              v-for="(cate, idx) in cateArr"
              :key="cate.cate_id"
              @click="handleCate(idx, cate)"
            >
              {{ cate.cate_name }} 【{{ cate.list.length }}】
            </div>
          </div>
          <div class="goods goodsScroll">
            <el-checkbox-group v-model="checkedCities1">
              <el-checkbox
                @change="
                  handleCheckedCitiesChange(item.is_bind, cateIndex, item.id)
                "
                v-for="item in cateArr[cateIndex].list"
                :label="item.id"
                :key="item.id"
              >
                <div class="flex" style="margin: 5px 0">
                  <div style="margin-right:20px;">
                    <div>{{ item.good_name }}</div>
                    <div>{{ item.code }}</div>
                  </div>
                  <!-- 供应商、采购员需要展示已绑定 -->
                  <template v-if="!!showBind">
                    <el-tooltip placement="top" effect="light" v-if="item.bind_list.length > 0">
                      <div slot="content" class="flex">
                          <span>已绑定：</span>
                          <div v-for="(v,i) in item.bind_list" :key="i">
                            <span>{{v}}</span>
                            <span v-if="i < item.bind_list.length -1">、</span>
                          </div>
                      </div>
                      <i class="el-icon-s-check" style="font-size:22px"></i>
                    </el-tooltip>
                  </template>
                </div>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <el-empty v-else :image-size="100" description="未查到相关商品"></el-empty>
      </div>
    </div>
 
    <template #footer>
      <el-row type="flex" justify="center">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import { mixinTable } from '@/mixins/table.js'
export default {
  name: "BuyerSelGoods",
  mixins: [mixinTable],
  props: {
    dialogTitle: { required: false, type: String, default: "负责供货商品" },
    childText: { required: false, type: String, default: "" },
    getDataApi: { required: true, type:String, default: '' },
    setDataApi: { required: true, type:String, default: '' },
    showBind: { required: true, type:Boolean, default: false },  // 是否展示已绑定的供应商/采购员
    sourceType: {required: true, type:Number, default: 1 }, // 来源 1:供应商，2:采购员，3:一品多商，4：下单分类
  },
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      visible: false,
      isFirst:0,
      loading: false,
      form: {
        goods_name: "",
        merchant_id: '',//客户id
      },
      cateArr: [
        {
          list: [],
        },
      ], // 分类
      cateIndex: 0, // 分类索引下标
      idx1: 0,
      idx: 0,
      bindingArr: [], // 已绑定商品

      checkAll: false,
      checkedCities: [],
      checkedCities1: [],
      isIndeterminate: true,
      currrRow: {},
      bindingNum: 0, // 已绑定商品数量
      init: [],
      curr_cate_sel: {
        cate_id: "",
        cate_name: "",
        list: [],
      }, // 待选商品当前位置
      merchantArr: [],
    };
  },
  methods: {
    getDetail(row) {
      console.log(row.id);
      this.currrRow = row;
      this.$nextTick(() => {
        this.getList();
      })
    },
    getList() {
      let _params = {};
      // sourceType来源 1:供应商，2:采购员，3:一品多商， 4：下单分类
      if(this.sourceType == 1 || this.sourceType == 2) {
        _params = {
          supplier_id: this.currrRow.id, // 供应商id
          keyword: this.form.goods_name,
        };
      } else if (this.sourceType == 3 || this.sourceType == 4) {
        _params = {
          id: this.currrRow.id, // 来源3时是专供列表id，来源4时是下单分类id
          keyword: this.form.goods_name,
          merchant_id: this.form.merchant_id, // 客户id （来源3时需要）
        }
      }
      this.isFirst++;
      this.$http
        .get(this.getDataApi, { params: _params })
        .then((res) => {
          if (res.code == 1) {
            this.cateIndex = 0;
            this.idx1 = 0;
            this.idx = 0;
            this.curr_cate_sel= {
              cate_id: "",
              cate_name: "",
              list: [],
            };// 待选商品当前位置
            if(!!_params.keyword || this.isFirst > 1) {
              const cateArr = res.data;
              if(res.data.length > 0) {
                cateArr.map((v, k) => {
                  if (k == 0) {
                    v.show = 1;
                  } else {
                    v.show = 0;
                  }
                });
                this.cateArr = cateArr;
                this.$set(this.cateArr);
              } else {
                this.cateArr = [{list:[]}]
              }
            } else {
              const cateArr = res.data;
              cateArr.map((v, k) => {
                if (k == 0) {
                  v.show = 1;
                } else {
                  v.show = 0;
                }
              });
              this.cateArr = cateArr;
              this.$set(this.cateArr)
              const init = [];
              res.data.map((v) => {
                let bindList = [];
                v.list.map((j) => {
                  if (j.is_bind == 1) {
                    this.checkedCities.push(j);
                    this.checkedCities1 = this.checkedCities.map(item =>item.id);
                    bindList.push(j);
                  }
                });
                if (bindList.length > 0) {
                  init.push({
                    cate_id: v.cate_id,
                    cate_name: v.cate_name,
                    list: bindList,
                  });
                }
              });
              if(this.bindingArr.length == 0) {
                this.bindingArr = init;
              }
              this.bindingNum = this.one(this.bindingArr);
            }
          }
        });
    },
    one(arr) {
      let sum = 0;
      arr.map((item) => {
        if(!!item.list && item.list.length > 0) {
          sum += Number(item.list.length);
        }
      });
      return sum;
    },
    reset(done) {
      this.checkedCities = [];
      this.checkedCities1 = [];
      this.cateIndex = 0;
      this.bindingArr = [];
      this.form.goods_name = '';
      this.isFirst = 0;
      this.childText = '';
      
      this.isChange = false;
      done && done();
    },
    toggle(show) {
      this.visible = show;
      !show && this.reset();
    },
    // 点击分类
    handleCate(idx, cate) {
      this.cateIndex = idx;
      this.curr_cate_sel = cate; // 待选商品当前位置
      console.log(cate, "99---");
      console.log(this.cateArr, "99***");
    },
    handleCate1(index) {
      this.idx1 = index;
    },
    
    // 是否全选
    handleCheckAllChange(val) {
      let _cate_id = !!this.curr_cate_sel.cate_id
      ? this.curr_cate_sel.cate_id
      : this.cateArr[0].cate_id;
      console.log(_cate_id, "a0")
      if (val === true) {
        //全选
        this.cateArr[this.cateIndex].list.forEach((v) => {
          v.is_bind = 1;
          this.checkedCities.push(v);
          //this.checkedCities1 = this.checkedCities.map(item =>item.id);
          this.checkedCities1.push(v.id);
        });
        console.log( this.cateArr[this.cateIndex].list,'ydydy');
         this.isIndeterminate = true;
        console.log(this.checkedCities, "checkedCities");
        let curlist = this.cateArr[this.cateIndex].list;
        this.cateArr[this.cateIndex].list = curlist.map((a) => {
          return {
            ...a,
            is_bind: 1,
          };
        });

        let _list = this.curr_cate_sel.cate_id
          ? this.curr_cate_sel.list
          : this.cateArr[0].list;
        let curSellist = _list.map((v) => {
          return {
            ...v,
            is_bind: 1,
          };
        });

        console.log(this.bindingArr, "bindingArr")
        console.log(_cate_id, "_cate_id")

        let exist2 = this.bindingArr.filter((v) => _cate_id == v.cate_id);
        console.log(exist2,"exist2")

        if (exist2.length > 0) {
          console.log("*2");
          this.bindingArr.map((v) => {
            if (v.cate_id == _cate_id) {
              v.list = curSellist;
            }
          });
        } else {
          console.log("*3");
          this.bindingArr.push({
            cate_id: this.curr_cate_sel.cate_id
              ? this.curr_cate_sel.cate_id
              : this.cateArr[0].cate_id,
            cate_name: this.curr_cate_sel.cate_name
              ? this.curr_cate_sel.cate_name
              : this.cateArr[0].cate_name,
            list: curSellist,
          });
        }
        this.$set(this.cateArr);
        this.bindingNum = this.one(this.bindingArr);
      } else {
        //取消全选
        this.idx1 = 0; // 已绑定商品索引归0
        this.bindingArr.forEach((item, i) => {
          if (item.cate_id == _cate_id) {
            this.bindingArr.splice(i, 1);
          }
        });
        let curlist2 = this.cateArr[this.cateIndex].list;
        this.cateArr[this.cateIndex].list = curlist2.map((v) => {
          return {
            ...v,
            is_bind: 0,
          };
        });
        this.checkedCities = this.checkedCities.filter(v => v.cate_id != _cate_id);
        this.checkedCities1 = this.checkedCities.map(item => item.id);
        this.bindingNum = this.one(this.bindingArr);
      }
      this.isIndeterminate = false;
    },

    // 单条勾选
    handleCheckedCitiesChange(is_bind, cateIndex, goodsId) {
      console.log(is_bind, "is_bind");
      this.cateArr.map((v, k) => {
        if (k == cateIndex) {
          v.list.map((v2) => {
            if (v2.id == goodsId) v2.is_bind = is_bind ? 0 : 1;
          });
        }
      });
      const cateList = this.cateArr.filter((v, k) => k == cateIndex);
      const cate = cateList.length > 0 ? cateList[0] : {};
      console.log(cate, 123);
      const goodsList = cate.list.filter((v) => v.id == goodsId);
      const goods = goodsList.length > 0 ? goodsList[0] : {};
      // 已选择商品
      const exist = this.bindingArr.filter((v) => cate.cate_id == v.cate_id);
      if(this.isFirst > 1){
        is_bind = this.checkedCities1.indexOf(goodsId) >= 0 ? 0 : 1;
      }
      if (is_bind == 1) {
        this.bindingNum = Number(this.bindingNum) - 1;
        this.bindingArr.map((v) => {
          if (v.cate_id == cate.cate_id) {
            let list = v.list.filter((v2) => v2.id != goodsId);
            v.list = list;
          }
        });

        console.log(this.checkedCities, "this.checkedCities")
        console.log(goodsId, "goodsId")
        let arr = this.checkedCities.filter((item) => item.id != goodsId);
        this.checkedCities = arr;
        this.checkedCities1 = this.checkedCities.map(item =>item.id);

      } else {
        // 新增
        console.log("*1");
        console.log(exist);
        this.bindingNum = Number(this.bindingNum) + 1;
        if (exist.length > 0) {
          console.log("*2");
          this.bindingArr.map((v) => {
            if (v.cate_id == cate.cate_id) {
              v.list.push(goods);
            }
          });
        } else {
          console.log("*3");
          this.bindingArr.push({
            cate_id: cate.cate_id,
            cate_name: cate.cate_name,
            list: [goods],
          });
        }

        // return
        this.checkedCities.push(goods)
        this.checkedCities1 = this.checkedCities.map(item =>item.id);
      }

      // console.log(value,"------------******");
      let _cate_id = !!this.curr_cate_sel.cate_id
      ? this.curr_cate_sel.cate_id
      : this.cateArr[0].cate_id;
      let num = this.bindingArr.filter((v) => v.cate_id == _cate_id);
      let checkedCount = num[0].list.length;
      this.checkAll = checkedCount === this.cateArr[this.cateIndex].list.length;
      this.isIndeterminate =
        checkedCount > 0 &&
        checkedCount < this.cateArr[this.cateIndex].list.length;
    },
    del(goods) {
      this.bindingNum = Number(this.bindingNum) - 1;
      this.cateArr.map((v) => {
        if (v.cate_id == goods.cate_id) {
          v.list.map((v2) => {
            if (v2.id == goods.id) v2.is_bind = 0;
          });
        }
      });
      this.bindingArr.map((v) => {
        if ((v.cate_id == goods.cate_id)) {
          let list = v.list.filter((v2) => v2.id != goods.id);
          v.cate_id = v.cate_id;
          v.list = list;
        }
      });
      console.log(this.checkedCities, "this.checkedCities")
      let arr = this.checkedCities.filter((item) => item.id != goods.id);
      this.checkedCities = arr;
      this.checkedCities1 = this.checkedCities.map(item =>item.id);
    },
    // 提交
    confirm() {
      let arr = this.bindingArr.reduce((a,b) => {
        return a.concat(b.list)
      },[]);
      if(arr.length < 1) {
        this.$message.warning('请选择需要绑定的商品')
        return
      }
      let _ids = arr.map(v => {
        return v.id
      }).join("|")
      // sourceType来源 1:供应商，2:采购员，3:一品多商， 4:下单分类
      let _params = {};
      if(this.sourceType == 1 || this.sourceType == 2) {
        _params = {
          supplier_id: this.currrRow.id, // 供应商ID
          goods_ids: _ids,// 已选择需绑定的商品id
        }
      } else if(this.sourceType == 3) {
        _params = {
          id: this.currrRow.id, // 专供列表id
          goods_id: _ids,// 已选择需绑定的商品id
          merchant_id: this.currrRow.merchant_id, //  //商户ID
          supplier_id: this.currrRow.supplier_id, // 供应商/采购员ID
          address_id: this.currrRow.address_id, // 食堂名称
        }
      } else if(this.sourceType == 4) {
        _params = {
          id: this.currrRow.id, // 下单分类ID
          goods_ids: arr.map(v => { return v.id }).join(","), // 要逗号分隔
        }
      }
      console.log(_params,"_params");
      // return
      this.$http.post(this.setDataApi, _params).then((res) => {
        if (res.code == 1) {
          this.$message.success("操作成功！");
          this.toggle(false);
          this.$emit("refresh");
          this.checkedCities = [];
          this.checkedCities1 = [];
          this.cateIndex = 0;
          this.isFirst = 0;
          this.curr_cate_sel = {
            cate_id: "",
            cate_name: "",
            list: [],
          } // 待选商品当前位置
        }
      });
    },
    
  },
};
</script>
<style scoped>
::v-deep .el-checkbox {
  display: block;
}
.flex {
  display: flex;
}
.boxWrap {
  display: flex;
  justify-content: space-around;
  max-height: 500px;
}
.title {
  font-weight: bold;
  padding: 10px 0;
}
.line {
  width: 1px;
  height: 520px;
  margin-right: 20px;
  background: #777;
}
.left {
  flex: 2;
}
.right {
  flex: 3;
}
.cateTitle {
  cursor: pointer;
  padding: 10px 2px;
}
.active {
  background: #eaf7f0;
  color: #5daf34;
}
.content {
  display: flex;
}
.cate {
  width: 132px;
  max-height: 460px;
  overflow-y: scroll;
}
.goods {
  margin-left: 30px;
  max-height: 460px;
  overflow-y: scroll;
}

.cate::-webkit-scrollbar {
  width: 0;
}
.goods::-webkit-scrollbar {
  width: 0;
}
.selGoodsTop {
  border: solid 1px #2cb167;
  margin-bottom: 10px;
  padding: 5px;
  background: #eaf7f0;
}
.goodsScroll {
  min-width: 260px;
}
.goodsScroll::-webkit-scrollbar {
  width:6px;
  background: #eee;
  border-radius: 6px;
}
.goodsScroll::-webkit-scrollbar-thumb {
  background: #8d8d8d;
  border-radius: 3px;
}
</style>
